import React from "react"
import {Container, Row,Col} from "react-bootstrap"

import AniLink from "gatsby-plugin-transition-link/AniLink";
import MultipleGridImages from 'react-multiple-image-grid'
import Layout from "../components/layout"
import Proj from "../components/project"
import SEO from "../components/seo"

const Gallery = ({data}) => {
const bg = "images/jurni/jurni4.jpg";
const bg2 = "images/jurni/jurni8.jpg";
 return (
  <Layout>
    <SEO title="Project page template" />
    <Container fluid>
        <Row className="proy align-items-center" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.616), rgba(0, 0, 0, 0.2)),url('`+bg+`')`}}>
            <Col md={{span:6,offset:3}} className="proy-title">
                <h1 className="mb-3">Our Work</h1>
                <p className="mt-4">Single galleries for single projects</p>
            </Col>
        </Row>
    </Container>

<Container fluid>
{data.allStrapiAndimg.edges.map(document=>(
<>
<h2 className="text-center my-5">{document.node.name}</h2>
<Row className="align-items-center"> 
             
<MultipleGridImages className="p-0 m-0" images={document.node.gallery.map(gallery=>gallery.url)} />
    
</Row>
</>
))}
</Container>
   
  </Layout>
)
}

export default Gallery
export const pageQuery = graphql `  
  query GalleryQuery {
    allStrapiAndimg {
      edges {
        node {
          name
          description
          gallery{
              url
          }
        }
      }
    }
  }
`
